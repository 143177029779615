/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Button,
  CurrencyFormat,
  Layout,
  SEO,
} from '../../../ui'
import useStyles from './account-referral-style'
import { combineClassNames } from '../../../helpers'

function AccountReferralView(props) {
  const {
    seoTitle,
    code,
    spendings,
    isBrowserSupportCopyText,
    onCopyText,
    onShare,
  } = props
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <Layout login>
      <SEO title={seoTitle} />
      <div className={styles.constinerFluid}>
        <div className={styles.container}>
          <div className={styles.shareCode}>
            <div className={styles.header}>
              <h2 className={styles.headerTitle}>{t('screens.accountReferral.shareCode.title')}</h2>
              <p className={styles.headerDescription}>{t('screens.accountReferral.shareCode.description')}</p>
            </div>
            <button
              type="button"
              className={combineClassNames([
                styles.referralCode,
                isBrowserSupportCopyText && styles.referralCodeClickable,
              ])}
              onClick={onCopyText}
              disabled={_.isEmpty(code)}
            >
              <span className={styles.referralCodeText}>{code}</span>
              {
                isBrowserSupportCopyText && (
                  <span
                    className={combineClassNames([
                      styles.referralCodeText,
                      styles.referralCodeTextCopy,
                    ])}
                  >
                    {t('screens.accountReferral.shareCode.copyMessage')}
                  </span>
                )
              }
            </button>
            <div className={styles.buttons}>
              <Button
                inline
                text={t('screens.accountReferral.shareCode.shareButton')}
                className={styles.shareButton}
                onClick={onShare}
                disabled={_.isEmpty(code)}
              />
            </div>
          </div>
          <div className={styles.spendings}>
            <div className={styles.header}>
              <h2 className={styles.headerTitle}>{t('screens.accountReferral.spendings.title')}</h2>
            </div>
            <div className={styles.list}>
              {
                _.map(spendings, ({ title, description, amount }, index) => (
                  <dl key={`spendings_item_${index}`} className={styles.item}>
                    <dt className={styles.itemLeft}>
                      <p
                        className={combineClassNames([
                          styles.itemText,
                          styles.itemTextTitle,
                        ])}
                      >
                        {title}
                      </p>
                      <p
                        className={combineClassNames([
                          styles.itemText,
                          styles.itemTextDesc,
                        ])}
                      >
                        {description}
                      </p>
                    </dt>
                    <dd className={styles.itemRight}>
                      <p
                        className={combineClassNames([
                          styles.itemText,
                          styles.itemTextAmount,
                        ])}
                      >
                        <CurrencyFormat value={amount} />
                      </p>
                    </dd>
                  </dl>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AccountReferralView
